import { render, staticRenderFns } from "./OsTableListV2.vue?vue&type=template&id=334a53bd"
import script from "./OsTableListV2.vue?vue&type=script&lang=js"
export * from "./OsTableListV2.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports